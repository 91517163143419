<template>
  <div class="container">
    <div class="row">
      <div class="col">
        {{ message }}
      </div>
      <data-form2
        :data="expData"
        :formFields="formFields"
        @changed="inputChanged"
        :readonly="true"
        minheight="380px"
      />
    </div>
    <div class="row">
      <div class="col">
        <div class="actions">
          <vue-excel-xlsx
            ref="xlsxData"
            style="display: none"
            class="ms-5 btn btn-warning bi bi-download"
            :data="jsonData['data']"
            :columns="jsonData['columns']"
            file-name="Exported_data"
            :file-type="'xlsx'"
            :sheet-name="'Export'"
          >
          </vue-excel-xlsx>
          <button
            type="button"
            class="btn btn-success bi bi-download"
            @click="clickOk"
          >
            Export
          </button>
          <button
            type="button"
            class="btn btn-danger bi bi-x"
            @click="closeModal"
          >
            Zatvori
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataForm2 from "../../widgets/DataForm2.vue";
import dateUtils from "../../../utils/date-common";
import invoiceDataService from "../../../services/invoice.service";
export default {
  name: "export-data",
  components: {
    DataForm2,
  },
  props: {
    endpoint: {
      type: String,
      required: true,
      default: "",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      jsonData: [],
    };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [
            {
              from: { label: "Od datuma", type: "date" },
            },
            { to: { label: "Do datuma", type: "date" } },
            {},
            {},
          ],
        ],
      };
    },
    expData() {
      return {
        from: dateUtils.getFirstDayOfsMonth().toISOString().substring(0, 10),
        to: new Date().toISOString().substring(0, 10),
      };
    },
  },
  methods: {
    inputChanged(e, name) {
      this.expData[name] = e;
    },
    closeModal() {
      this.$store.commit("app/hideModal");
    },
    async clickOk() {
      invoiceDataService
        .getFromTo("Normal", this.expData.from, this.expData.to)
        .then((res) => {
          this.jsonData["columns"] = [
            {
              label: "Id",
              field: "id",
            },
            {
              label: "Datum Fakture",
              field: "doc_date",
              dataFormat: dateUtils.formatDate,
            },
            {
              label: "Valuta",
              field: "exchange",
            },
            {
              label: "Outbound",
              field: "Reservation.reservation_num",
            },
            {
              label: "Outbound status",
              field: "Reservation.status",
            },
            {
              label: "Poslovnica",
              field: "Station.code",
            },
            {
              label: "Kupac",
              field: "Customer.name",
            },
            {
              label: "Država",
              field: "Reservation.Country.name",
            },
            {
              label: "ITS Broj",
              field: "additional.its_no",
            },
            {
              label: "Ulazna faktura",
              field: "additional.incoming_invoice",
            },
            {
              label: "Broj faktura",
              field: "doc_nr",
            },
            {
              label: "Ukupan iznos EUR",
              field: "iznos_exc",
            },
            {
              label: "Ukupan iznos RSD",
              field: "iznos",
            },
            {
              label: "Osnovica PDV RSD",
              field: "osn",
            },
            {
              label: "PDV RSD",
              field: "pdv",
            },
            {
              label: "Ukuona provizija RSD Sa PDV",
              field: "prov",
            },
          ];
          this.jsonData["data"] = res.data.map((i) => {
            let mult = i.invoice_status == "Deleted" ? -1 : 1;
            const iznos_exc = mult * i.invoice_items.reduce((accumulator, item) => {
              return Number.parseFloat(
                Number.parseFloat(accumulator) +
                  Number.parseFloat(item.price_exc) +
                  Number.parseFloat(item.tax_exc)
              ).toFixed(2);
            }, 0);
            const iznos = mult * i.invoice_items.reduce((accumulator, item) => {
              return Number.parseFloat(
                Number.parseFloat(accumulator) +
                  Number.parseFloat(item.price) +
                  Number.parseFloat(item.tax)
              ).toFixed(2);
            }, 0);
            const osn = mult * i.invoice_items
              .filter((itm) => Number.parseFloat(itm.tax) > 0)
              .reduce((accumulator, item) => {
                return Number.parseFloat(
                  Number.parseFloat(accumulator) + Number.parseFloat(item.price) //+
                  // Number.parseFloat(item.tax)
                ).toFixed(2);
              }, 0);
            const pdv = mult * i.invoice_items.reduce((accumulator, item) => {
              return Number.parseFloat(
                Number.parseFloat(accumulator) + Number.parseFloat(item.tax)
              ).toFixed(2);
            }, 0);
            const prov = 1 * (
              Number.parseFloat(osn) + Number.parseFloat(pdv)
            ).toFixed(2);
            return { ...i, iznos_exc, iznos, osn, pdv, prov };
          });
          const sum = {
            doc_nr: "UKUPNO:",
            iznos_exc: 0,
            iznos: 0,
            osn: 0,
            pdv: 0,
            prov: 0,
          };
          
          for (let index = 0; index < this.jsonData["data"].length; index++) {
            const el = this.jsonData["data"][index];
            sum.iznos_exc = (
              Number.parseFloat(sum.iznos_exc) + Number.parseFloat(el.iznos_exc)
            ).toFixed(2);
            sum.iznos = (
              Number.parseFloat(sum.iznos) + Number.parseFloat(el.iznos)
            ).toFixed(2);
            sum.osn = (
              Number.parseFloat(sum.osn) + Number.parseFloat(el.osn)
            ).toFixed(2);
            sum.pdv = (
              Number.parseFloat(sum.pdv) + Number.parseFloat(el.pdv)
            ).toFixed(2);
            sum.prov = (
              Number.parseFloat(sum.prov) + Number.parseFloat(el.prov)
            ).toFixed(2);
          }

          this.jsonData["data"].push(sum);

          setTimeout(() => {
            this.$refs["xlsxData"].exportExcel();
          }, 200);
        });
    },
  },
};
</script>

<style>
/* .vuejs3-datepicker__calendar {
      position: fixed !important;
    } */
</style>