<template>
  <div style="overflow: unset">
    <data-form2
      :key="'pay' + nextKey"
      :data="itemData"
      :formFields="formFields"
      @save="saveData"
      @cancel="cancel"
      minheight="250px"
      :nooverflow="true"
      @changed="inputChanged"
    />
  </div>
  <!-- <div>{{ errorMessage }}</div> -->
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import utils from "../../utils/common";
import ReservationService from "../../services/reservation.service";
import ExchangeRatesDataService from "../../services/exchange-rates.service";

export default {
  name: "reservation-value-form",
  props: ["reservationId", "type", "its_no", "incoming_invoice"],
  components: {
    DataForm2,
  },
  data() {
    return {
      nextKey: 0,
      paymentKey: 0,
    };
  },
  computed: {
    taxRates() {
      return this.$store.state.app.taxRates;
    },
    formFields() {
      let proform = {};
      if (this.type == "Proform") {
        proform = this.$store.state.app.articles[this.type].map((i) => {
          const fieldName = `price_exc_${i.id}`;
          const res = {};
          res[fieldName] = {
            label: i.name,
            type: "number",
            description: `Unesite bruto iznos za ${i.name}`,
          };
          // console.log({ res });
          return res;
        });
      }
      return {
        rows: [
          this.type == "Proform"
            ? [...proform, {}]
            : [
                { price_exc: { label: "Iznos u valuti", type: "number" } },
                this.type == "Normal"
                  ? {
                      osnovica: {
                        label: "Osnovica za proviziju",
                        type: "number",
                      },
                    }
                  : {},
                this.type == "Normal"
                  ? { osnovica_p: { label: "% provizije", type: "number" } }
                  : {},
              ],
          this.type == "Normal"
            ? [
                { its_no: { label: "ITS No." } },
                { incoming_invoice: { label: "Broj ulazne fakture" } },
                {},
              ]
            : null,
          [
            {
              exchange_date: {
                label: "Datum kursa",
                type: "date",
                max: new Date().toISOString().substring(0, 10),
              },
            },
            {
              exchange: {
                label: "Valuta",
                type: "dropdown",
                dropdownHeight: "150px",
                list: [
                  { id: "eur", name: "EUR" },
                  { id: "usd", name: "USD" },
                  { id: "aud", name: "AUD" },
                  { id: "chf", name: "CHF" },
                  { id: "gbp", name: "GBP" },
                  { id: "sek", name: "SEK" },
                  { id: "eur_adiko", name: "EUR Adiko" },
                  { id: "usd_adiko", name: "USD Adiko" },
                  { id: "aud_adiko", name: "AUD Adiko" },
                  { id: "chf_adiko", name: "CHF Adiko" },
                  { id: "gbp_adiko", name: "GBP Adiko" },
                  { id: "sek_adiko", name: "SEK Adiko" },
                ],
              },
            },
            // { val_date: { label: "Datum valute", type: "date" } },
            {
              exchange_value: {
                label: "Kurs",
                type: "number",
                description:
                  "Ukoliko kurs nije definisan za izabrani datum, možete ga preuzeti pomoću forme Kursna lista",
                key: "exc" + this.excKey,
              },
            },
          ],
          [
            {
              price: {
                label: "Iznos",
                type: "hidden",
                disabled: true,
                key: this.paymentKey,
              },
            },
            {},
            {},
            {},
          ],
        ],
      };
    },
    itemData() {
      // console.log({ route: this.$route.params.id, invoice: this.invoice });
      return {
        its_no: this.its_no,
        incoming_invoice: this.incoming_invoice,
        exchange: "eur",
        exchange_value: this.getExchange(new Date()),
        exchange_date: new Date().toISOString().substring(0, 10),
        osnovica: 0.0,
        osnovica_p: 0.0,
      };
    },
    cachedVals() {
      return {
        price: "",
        price_exc: "",
        payment: "",
        payment_exc: "",
      };
    },
  },
  methods: {
    inputChanged(e, name) {
      this.itemData[name] = e;

      if (name.startsWith("price_exc")) {
        this.itemData[name] = Number.parseFloat(e).toFixed(2);
      }

      if (name == "exchange") {
        this.getExchange(this.itemData.exchange_date);
      }

      if (name == "exchange_date") {
        this.getExchange(e);
      }

      this.paymentKey++;
    },
    getExchange(date) {
      ExchangeRatesDataService.getByDate(date).then((res) => {
        const exc = res.data[this.itemData.exchange];
        this.itemData.exchange_value = exc;
        this.nextKey++;
      });
    },
    updateExcPrice(val) {
      this.updatePrice(val, "price_exc", "price");
    },
    updatePrice(val, itemExc, item) {
      // console.log({ itemExc, item });
      this.itemData[itemExc] = Number.parseFloat(val).toFixed(2);
      this.itemData[item] = Number.parseFloat(
        val * this.itemData.exchange_value
      ).toFixed(2);
      // console.log({ data: this.itemData });
      this.paymentKey++;
    },
    saveData(newData) {
      // console.log({ newData });
      if (this.type == "Proform") {
        for (let i of this.$store.state.app.articles[this.type]) {
          const fieldName = `price_exc_${i.id}`;
          // console.log({ fieldName, i });
          if (!newData || !newData[fieldName] || newData[fieldName] == "NaN") {
            utils.showAlert("danger", `Iznos ${i.name} je obavezan!`);
            return;
          } else {
            if (newData.price_exc <= 0) {
              utils.showAlert("danger", `Iznos ${i.name} mora biti veci od 0!`);
              return;
            }
          }
        }
      } else {
        if (!newData || !newData.price_exc || newData.price_exc == "NaN") {
          utils.showAlert("danger", `Iznos je obavezan!`);
          return;
        } else {
          if (newData.price_exc <= 0) {
            utils.showAlert("danger", `Iznos mora biti veci od 0!`);
            return;
          }
        }
      }
      if (!newData || !newData.exchange_value) {
        utils.showAlert("danger", `Kurs je obavezan!`);
        return;
      }
      // if (!newData || !newData.price) {
      //   utils.showAlert("danger", `Iznos nije preračunat!`);
      //   return;
      // }
      ReservationService.createDocument({
        id: this.reservationId,
        type: this.type,
        its_no: newData.its_no,
        incoming_invoice: newData.incoming_invoice,
        exchange: newData.exchange,
        exchange_date: newData.exchange_date,
        exchange_value: newData.exchange_value,
        items: this.$store.state.app.articles[this.type].map((i) => {
          const tax = this.taxRates.find((t) => t.id == i.tax);
          // const taxKoef = Number.parseFloat(tax.rate) / 100 + 1;
          const koef = 1 + Number.parseFloat(tax.rate) / 100;
          const koefTax = 1 - 1 / (1 + Number.parseFloat(tax.rate) / 100);
          let price = Number.parseFloat(
            newData.price_exc * newData.exchange_value
          ).toFixed(2);
          let price_exc = newData.price_exc;
          if (this.type == "Proform") {
            const fieldName = `price_exc_${i.id}`;
            price = Number.parseFloat(
              newData[fieldName] * newData.exchange_value
            ).toFixed(2);
            price_exc = newData[fieldName];
          }
          if (this.type == "Normal") {
            const ofee =
              newData.price_exc -
              newData.osnovica * (newData.osnovica_p / 100) -
              newData.osnovica * (newData.osnovica_p / 100) * 0.2;
            if (Number.parseFloat(tax.rate) == 0.0) {
              if (newData.osnovica > 0) {
                price = (ofee - ofee * 0.05) * newData.exchange_value;
                price_exc = ofee - ofee * 0.05;
              } else {
                price = price - price * 0.05;
                price_exc = price_exc - price_exc * 0.05;
              }
            }
            if (Number.parseFloat(tax.rate) == 20.0) {
              if (newData.osnovica > 0) {
                price =
                  Number.parseFloat(price) -
                  (ofee - ofee * 0.05) * newData.exchange_value;
                price_exc = Number.parseFloat(price_exc) - (ofee - ofee * 0.05);
              } else {
                price = price * 0.05;
                price_exc = price_exc * 0.05;
              }
            }
          }
          return {
            quantity: 1,
            price: price / koef,
            price_exc: price_exc / koef,
            tax: price * koefTax, //(1 - 1 / taxKoef),
            tax_exc: price_exc * koefTax, //(1 - 1 / taxKoef),
            article_id: i.id,
          };
        }),
      })
        .then((res) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Dokument je kreiran!",
          });
          utils.confirmNo();
          this.$router.push({
            path: `/invoice/${this.type}/${res.data.data.id}`,
          });
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
      this.$store.commit("app/hideModal");
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
  },
};
</script>