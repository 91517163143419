<template>
  <div class="list row">
    <h2>Outbound</h2>
    <div class="actions d-flex flex-row-reverse">
      <button type="button" class="btn btn-primary bi bi-arrow-clockwise" @click="refreshData">
        Osveži tabelu
      </button>
      <button type="button" class="btn btn-success bi bi-plus" @click="addData">
        Novi Outbound
      </button>
    </div>
    <data-table url="reservation" :columns="columns" :headings="headers" :templates="templates"
      :noSort="['price_exc', 'payment_exc', 'price', 'payment']" :actions="actions" :extra_actions="extra_actions"
      ref="datatable" @yes="deleteData" name="Reservation" @loaded="onLoaded" @showComment="showComment">
      <template v-slot:tableExtraOptions>
        <vue-excel-xlsx class="ms-5 btn btn-warning bi bi-download" :data="jsonData['data']"
          :columns="jsonData['columns']" file-name="Outbound" :file-type="'xlsx'" :sheet-name="'Export'">
          Download
        </vue-excel-xlsx>
        <button type="button" class="ms-1 btn btn-secondary bi bi-filetype-xlsx" @click="exportData">
          Export
        </button>
      </template>
    </data-table>
  </div>
</template>

<script>
import ReservationService from "../../services/reservation.service";
import DataTable from "../widgets/DataTable.vue";
import utils from "../../utils/common";
import con from "../../constants/constants";
import dateUtils from "../../utils/date-common";
import RezervationValueForm from "./RezervationValueForm.vue";
import ListDialog from "../widgets/ListDialog.vue";
import ConfirmDialog from "../widgets/ConfirmDialog.vue";
import ExportDialog from "../reports/exports/export-data.vue";
// import FileUploader from "../widgets/FileUploader.vue";
import Files from "../files/Files.vue";

export default {
  name: "reservation",
  components: {
    DataTable,
    // ConfirmDialog,
  },
  data() {
    return {
      jsonData: [],
      columns: [
        "id",
        "createdAt",
        "voucher",
        "po_num",
        "status",
        "check_out",
        "check_in",
        "driver_name",
        "Buyer.name",
        "Country.name",
        "city",
        "reservation_num",
        "comment",
        // "price_exc",
        // "payment_exc",
        "price",
        "payment",
        "edit",
        "extra_actions",
      ],
      headers: {
        id: "Id",
        createdAt: "Datum",
        voucher: "Vaucher",
        po_num: "Po broj",
        status: "Status",
        check_out: "Check out",
        check_in: "Check in",
        driver_name: "Vozač",
        "Buyer.name": "Firma",
        "Country.name": "Država",
        city: "Grad",
        reservation_num: "Outbound Broj",
        comment: "Komentar",
        price: "Iznos BRUTO RSD",
        payment: "Uplaćeno RSD",
        edit: "",
        extra_actions: "Upload | PR | AV | KR/R",
      },
      templates: {
        check_in(h, row) {
          return dateUtils.formatDate(row.check_in);
        },
        check_out(h, row) {
          return dateUtils.formatDate(row.check_out);
        },
        price(h, row) {
          const tmp = utils.arrSum(
            row.invoices.filter((i) => i.invoice_type == "Proform"),
            "invoice_items",
            "price",
            "tax"
          );
          return tmp;
        },
        payment(h, row) {
          const tmp = utils.arrSum(
            row.invoices.filter((i) => i.invoice_type == "Proform"),
            "invoice_payments",
            "payment"
          );
          return tmp;
        },
      },
      actions: {
        btn1: {
          color: "primary",
          icon: "bi-pencil",
          action: (data) => {
            this.$router.push({
              path: `/reservation/${data.id}`,
              // params: { invoice: { data } },
            });
          },
        },
        delete: {
          // deleteData: this.deleteData,
          color: "danger",
          icon: "bi-trash",
          action: (data) => this.deleteData(data),
        },
      },
      extra_actions: {
        btn1: {
          count: "files",
          color: "warning",
          icon: "bi-download",
          action: (data) => this.addFile(data),
        },
        btn2: {
          countIcon: "Proform",
          color: "info",
          icon: "bi-journal-plus",
          // pref:"PR",
          action: (data) => this.addDoc("Proform", data),
        },
        btn3: {
          countIcon: "Advance",
          color: "purple",
          icon: "bi-journal-plus",
          // pref:"AR",
          action: (data) => this.addDoc("Advance", data),
        },
        btn4: {
          countIcon: "Normal",
          color: "indigo",
          icon: "bi-journal-plus",
          // pref:"R",
          action: (data) => this.addDoc("Normal", data),
        },
        // print: null,F
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    notImplemented(data) {
      utils.showAlert("info", `Opcija ${data} još uvek nije dostupna 🤷`);
    },
    showComment(data) {
      this.$store.commit("app/showModal", {
        modal: ConfirmDialog,
        params: {
          message: data,
          title: "Outbound Komentar: ",
          noActions: true,
          onYes: () => {
            this.$store.commit("app/hideModal");
          },
          onNo: () => {
            this.$store.commit("app/hideModal");
          },
        },
      });
    },
    addDoc(type, data) {
      if (data.invoices.filter((x) => x.invoice_type == type).length > 0) {
        const items = data.invoices.map((invoice) => {
          return invoice.invoice_type == type
            ? {
              label: `Status: <b>${con.statuses[invoice.invoice_status].label
                }</b> Tip: <b>${con.type[invoice.invoice_type].label}</b> ${invoice.doc_nr ? `Broj: <b>${invoice.doc_nr}</b>` : ""
                } Iznos BRUTO RSD: <b>${invoice.invoice_items.length > 0
                  ? invoice.invoice_items.reduce((accumulator, object) => {
                    return Number.parseFloat(
                      Number.parseFloat(accumulator) +
                      object.price * object.quantity +
                      Number.parseFloat(object.tax)
                    ).toFixed(2);
                  }, 0)
                  : 0
                }</b> Uplaceno RSD: <b>${invoice.invoice_payments.length > 0
                  ? invoice.invoice_payments.reduce((accumulator, object) => {
                    return Number.parseFloat(
                      Number.parseFloat(accumulator) +
                      Number.parseFloat(object.payment)
                    ).toFixed(2);
                  }, 0)
                  : 0
                } </b> `,
              url: `/invoice/${type}/${invoice.id}`,
              btnText: "Otvori",
              url2: `/invoice/print/${type}/${invoice.id}/1`,
              btnText2: "Print",
              target2: "blank",
              url3: `/invoice/print/${type}/${invoice.id}/0`,
              btnText3: "Print bez valute",
              target3: "blank",
            }
            : null;
        });
        const itemsByType = items.filter((item) => item != null);
        this.$store.commit("app/showModal", {
          modal: ListDialog,
          params: {
            title: "Outbound: " + data.id,
            message: `Povezani ${utils
              .typeName(type, 2)
              .toLowerCase()} za Outbound ${data.reservation_num}.`,
            items: itemsByType,
            actions:
              type != "Advance"
                ? {
                  btn: {
                    color: "primary",
                    icon: "bi-journal-plus",
                    label: `Kreiraj ${utils.typeName(type, 1).toLowerCase()}`,
                    action: () => this.createDoc(type, data),
                  },
                }
                : {},
          },
        });
      } else {
        if (type != "Advance") {
          this.createDoc(type, data);
        } else {
          utils.showAlert(
            "info",
            `Ne postoje povezani ${con.type[type].label.toLowerCase()}i.`
          );
        }
      }
    },
    createDoc(type, data) {
      this.$store.commit("app/showModal", {
        modal: RezervationValueForm,
        params: {
          type,
          title: "Unesi iznos u valuti: ",
          reservationId: data.id,
          its_no: data.its_no,
          incoming_invoice: data.incoming_invoice,
        },
      });
    },
    refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addFile(data) {
      console.log({ data });
      this.$store.commit("app/showModal", {
        modal: Files,
        params: {
          pid: data.id,
          parent: "reservation",
          title: "Lista dokumenata",
        },
      });
    },
    addData() {
      this.$router.push({
        path: `/reservation/0`,
        params: { data: {} },
      });
    },
    deleteData(data) {
      if (data.invoices && data.invoices.length > 0) {
        utils.showAlert(
          "danger",
          `Outbound ima povezabe dokumente. Brisanje nije moguce.`
        );
        return;
      }

      this.$store.commit("app/showModal", {
        modal: ConfirmDialog,
        params: {
          message: "Da li ste sigurni?",
          title: "Obrisi Outbound: " + data.id,
          onYes: () => {
            console.log({ data });
            ReservationService.delete(data.id)
              .then((response) => {
                this.$store.commit("app/showAlert", {
                  type: "success",
                  message: response.data.message,
                });
                this.$store.commit("app/hideModal");
              })
              .catch((e) => {
                console.log(e);
                this.$store.commit("app/showAlert", {
                  type: "danger",
                  message: e,
                });
              });
          },
          onNo: () => {
            this.$store.commit("app/hideModal");
          },
        },
      });
    },
    getJsonData() {
      return this.$refs[`${this.$route.params.type}_datatable`].onDownloadCsv();
    },
    onLoaded(data) {
      this.jsonData["data"] = data.data.rows.map((r) => {
        return {
          ...r,
          price: utils.arrSum(
            r.invoices.filter((i) => i.invoice_type == "Proform"),
            "invoice_items",
            "price"
          ),
          payment: utils.arrSum(
            r.invoices.filter((i) => i.invoice_type == "Proform"),
            "invoice_payments",
            "payment"
          ),
          PR: r.invoices.filter((i) => i.invoice_type == "Proform").length,
          AV: r.invoices.filter((i) => i.invoice_type == "Advance").length,
          'KR/R': r.invoices.filter((i) => i.invoice_type == "Normal").length,
        };
      });
      const res = Object.entries(this.headers)
        .map((k) => {
          if (k[1]) {
            if (k[1] != "Upload | PR | AV | KR/R") {
              return {
                label: k[1],
                field: k[0],
              };
            } else {
              return null;
            }
          }
        })
        .filter((o) => !!o);
      res.push({
        label: "PR",
        field: "PR",
      });
      res.push({
        label: "AV",
        field: "AV",
      });
      res.push({
        label: "KR/R",
        field: "KR/R",
      });
      this.jsonData["columns"] = res;
    },
    exportData() {
      this.$store.commit("app/showModal", {
        modal: ExportDialog,
        params: {
          endpoint: "apiEndpoint",
          // title: "Export podataka",
          title: "Export faktura sa Outboundom za period",
        },
      });
    },
  },
};
</script>

<style>
.Reservation_table_row>td:nth-child(1),
.Reservation_table_row>td:nth-child(2),
.Reservation_table_row>td:nth-child(5),
.Reservation_table_row>th:nth-child(1),
.Reservation_table_row>th:nth-child(2),
.Reservation_table_row>th:nth-child(5) {
  text-align: center;
}

.Reservation_table_row>td:nth-child(14),
.Reservation_table_row>td:nth-child(15),
.Reservation_table_row>th:nth-child(14),
.Reservation_table_row>th:nth-child(15) {
  text-align: right;
  font-weight: bold;
}

.Reservation_table_row>td:nth-child(9) {
  min-width: 250px;
  max-width: 250px;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Reservation_table_row>td:nth-child(8) {
  min-width: 120px;
  font-size: small;
}

.Reservation_table_row>td:nth-child(13) {
  min-width: 150px;
  font-size: small;
}
</style>
